const width = window.screen.width
let param = width > 960 ? 12 : width > 600 ? 8 : 6
export const defaultFilters = {
    pagination: {
        limit: param,
        page: 1,
    },
    sortBy: 'id',
    sortDesc: true,
}
