import Vue from "vue";
import {stringCroppedView} from "@/helpers/crop-string";

Vue.filter('stripHTML', function (value) {
  const div = document.createElement('div')
  div.innerHTML = value
  return div.textContent || div.innerText || '';
});

Vue.filter('helpSearchContent', str => {
  let div = document.createElement("div");
  div.innerHTML = str;
  return div.textContent || div.innerText || "";
});

Vue.filter('cropString', stringCroppedView)