export const locale = {
  articlePage: {
    title: 'Article de blog'
  },
  blog:{
    sliderArticle1:{
      title: 'Dictionnaire du marketing' ,
      desc: 'Voici comment le survente et la vente croisée pourraient augmenter vos revenus de 30 % !',
    },
    sliderArticle2:{
      title: 'Créez votre site web' ,
      desc: 'Guide complet : Comment structurer la meilleure page de destination longue',
    },
    sliderArticle3:{
      title: 'Meilleur outil pour les designers' ,
      desc: 'Récemment, j\'ai eu l\'occasion de découvrir SmartBase, et mon dieu, et mon dieu',
    },
    viewAll: 'Voir tout',
    sliderLink: 'Lire la suite',
  },
  support: {
    support: 'Support',
    title: 'Comment pouvons-nous vous aider?',
    commonSearches: 'Recherches courantes:',
    found: 'Vous n\'avez pas trouvé ce dont vous avez besoin?',
    touch: 'Contactez-nous - nous sommes heureux de vous aider!',
    placeholder: 'Posez une question...',
    button: 'Contactez-nous'
  },
  thankYou:{
    title: 'Vous êtes maintenant sur notre liste d\'attente exclusive !',
    text:'Nous vous informerons avec une invitation à votre compte gratuit dès que notre plateforme sera lancée. Restez à l\'écoute pour le voyage passionnant qui vous attend avec notre solution tout-en-un !',
    backHome: 'Retour à la page d\'accueil'
  },
  modal:{
    title: 'Veuillez fournir les informations\n' +
        'pour vérifier votre inscription',
    description:'Smartbase est sur le point d\'être dévoilé.  '+
        'En rejoignant notre liste d\'attente, vous serez parmi les premiers bénéficiaires.',
    email: 'E-mail*',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    phoneNumber: 'Numéro de téléphone',
    agreement: 'Vos données sont utilisées pour l\'inscription à la liste d\'attente et les emails de Smartbase. Désabonnez-vous à tout moment selon notre',
    link: 'Politique de confidentialité.',
    phEmail: 'Saisissez votre adresse e-mail',
    phFirstName: 'Saisissez votre prénom',
    phLastName: 'Saisissez votre nom de famille',
    phPhone: '(xxx) xxx xxxx',
    btn: 'Confirmer'
  },
  pricing: {
    name: 'Tarification',
    title: 'Choisissez le bon plan pour \n' +
        'votre entreprise',
    subtitle: 'Créez et optimisez facilement des entonnoirs de vente performants grâce à l\'IA',
    toggle:{
      title: 'Choisissez la période de facturation',
      btn1: 'Annuellement',
      btn2: 'Mensuellement'
    },
    cart:{
      starter: 'Démarrage',
      company: 'Entreprise',
      enterprise: 'Grande entreprise',
      user: 'par utilisateur/mois',
      btn: 'Commencer'
    },
    table: {
      titles:{
        tailwind: 'Code Tailwind CSS',
        figma: 'Fichiers de conception Figma',
        support: 'Support'
      },
      items:{
        basic: 'Composants de base',
        application: 'Interface utilisateur de l\'application',
        marketing: 'Interface utilisateur de précommande marketing',
        commerce: 'Interface utilisateur de précommande de commerce électronique',
        edition: 'Édition gratuite',
        community: 'Communauté',
        reports: 'Signalements de bogues et demandes de fonctionnalités',
        duration: 'Durée du support',
        year: '1 an'
      },
    },
    btn: 'Obtenez un compte gratuit',
    placeholder: 'Entrez votre adresse e-mail',
    links: {
      viewAll: ' (voir tout)',
      viewDemo: ' (voir la démo)',
      duplication:  '(dupliquer dans Figma)',
      preview: '(aperçu dans Figma)'
    }
  },
  mainPage: {
    title: 'Smart Base',
    revolutionizing: {
      title: 'Révolutionner<br>Votre Entreprise en Ligne',
      subtitle1: 'Libérez la Puissance de',
      subtitle2: 'et',
      subtitle3: 'Une Plateforme Tout-en-Un Pilotée par l\'IA',
      link1: 'Entonnoirs de Vente',
      link2: 'Création de Site Web',
      link3: 'Marketing par E-mail',
      join: 'Rejoignez plus de 1 200 personnes curieuses prêtes à découvrir',
      email: 'Adresse e-mail',
      btn: 'Rejoignez la liste d\'attente'
    },
    platforms: {
      title: 'Remplacez plusieurs abonnements par une plateforme unique et intégrée. Dites adieu à tous...'
    },
    integration: {
      chip: 'Intégration IA',
      title: 'L\'Intelligence Artificielle à Votre Service',
      subtitle: 'Vous exploiterez la puissance de l\'IA pour automatiser et optimiser votre contenu. Imaginez être capable de créer ' +
          'vos pages web, pages de vente, articles de blog, e-mails, tous générés efficacement par l\'IA. Cela libère votre temps ' +
          'pour vous concentrer sur la croissance de votre entreprise.'
    },
    solution: {
      chip: 'Fonctionnalités',
      title: 'Solution Tout-en-Un : Rationalisez votre entreprise en ligne en toute simplicité',
      subtitle: 'Des tonnes d\'autres adorent construire et expédier des sites avec smartbase.',
      replaces: 'Remplace',
      learnMore: 'En savoir plus',
      solutionItem1: {
        title: 'Créez des entonnoirs de vente',
        subtitle: 'Commencez votre parcours avec notre plateforme en créant des entonnoirs de vente hautement convertissants. Utilisez nos ' +
            'outils intuitifs pour concevoir chaque étape du parcours client, de l\'intérêt initial à l\'achat final, ' +
            'améliorant votre processus de vente avec efficacité et précision.'
      },
      solutionItem2: {
        title: 'Envoyez des campagnes par e-mail',
        subtitle: 'Impliquez votre public grâce à des campagnes par e-mail ciblées. Notre plateforme vous permet ' +
            'de créer, gérer et suivre facilement la performance de vos e-mails, en veillant à ce qu\'ils résonnent avec votre public' +
            'et génèrent des interactions significatives.'
      },
      solutionItem3: {
        title: 'Créez des sites web',
        subtitle: 'Construisez des sites web professionnels et réactifs sans aucune connaissance en programmation. Notre constructeur de site web par ' +
            'glisser-déposer est conçu pour vous aider à créer des espaces en ligne étonnants qui reflètent vraiment votre marque et ' +
            'attirent plus de visiteurs.'
      },
      solutionItem4: {
        title: 'Créez des webinaires en direct et des formations en ligne',
        subtitle: 'Élargissez votre portée et votre impact éducatif avec des cours en ligne et des webinaires en direct. Que ce soit pour l\'intégration, ' +
            'la formation ou l\'interaction avec votre public, nos outils facilitent la création et ' +
            'l\'hébergement de contenus éducatifs en ligne convaincants.'
      },
      solutionItem5: {
        title: 'Automatisez vos tâches',
        subtitle: 'Augmentez la productivité en automatisant les tâches répétitives. Des flux de travail marketing' +
            'aux processus de vente, nos outils d\'automatisation vous aident à gagner du temps, à réduire les erreurs ' +
            'et à vous concentrer sur des aspects plus stratégiques de votre entreprise.'
      },
      solutionItem6: {
        title: 'Gestion de la relation client',
        subtitle: 'Gérez efficacement vos relations clients. Notre système CRM intégré vous permet de suivre ' +
            'les interactions avec les clients, d\'analyser leur comportement et de personnaliser votre approche ' +
            'pour améliorer la satisfaction et la fidélité des clients.'
      },
    },
    getStarted: {
      title: 'Prêt à être en direct ?<br>IA simplifiée : un outil, plusieurs fonctions',
      btn: 'Rejoignez la liste d\'attente',
      btnJoinUs: 'Rejoignez-nous',
    },
    testimonials: {
      chip: 'Témoignages',
      title: 'Ne vous fiez pas seulement à nos paroles',
      subtitle: 'Voici ce que disent les gens à notre sujet'
    }
  },
  pricingPage: {
    title: 'Tarification',
  },
  errorPage: {
    title: '404',
    header: {
      meta: 'Oups ! Nous avons perdu cette page',
      ops: 'Oups!',
      title: 'Nous avons perdu cette page',
      text: 'Même avec la lumière la plus vive, nous n\'avons pas pu trouver ce que vous cherchez. Trouvons un meilleur endroit pour vous.',
      btn: 'Retour à la page d\'accueil',
    }

  },
  featuresWebinarPage: {
    title: 'Webinaire',
    hero: {
      chip: 'Webinaires en direct et formation en ligne',
      text: {
        main: 'Animez des webinaires, créez des cours - Aucune compétence technique requise',
        mainUp: 'Animez des webinaires, créez des cours',
        mainDown: '— Aucune compétence technique requise',
        additional: 'Les webinaires en direct et les cours en ligne offrent une stratégie engageante pour développer\n' +
          'votre entreprise numérique et établir un flux de revenus durable.'
      },
      btn: 'Rejoignez la liste d\'attente',
      btnGetFree: 'Obtenez mon compte gratuit',
    },
    logos: {
      text: 'Remplacez plusieurs abonnements par une plateforme unique et intégrée. Dites au revoir à tous...'
    },
    features: {
      chip: 'Fonctionnalités',
      title: 'Développez et lancez rapidement des webinaires et des cours en ligne',
      education: {
        title: 'Éducation avec l\'IA',
        text: 'Laissez l\'IA créer votre cours en ligne idéal et votre webinaire en direct sans effort.',
      },
      webinar: {
        title: 'Créez un webinaire en quelques clics',
        text: 'Créez facilement des pages à l\'aide de modèles avec notre constructeur intuitif.'
      },
      chat: {
        title: 'Chat en direct',
        text: 'L\'interaction en direct est cruciale pour des webinaires captivants.'
      },
      quiz: {
        title: 'Impliquez votre audience de manière significative',
        text: 'Fonctionnalités étudiantes sur mesure pour un apprentissage amélioré et une satisfaction accrue.'
      },
      course: {
        title: 'Concevez votre cours',
        text: 'Personnalisez votre cours sans effort avec notre constructeur flexible.'
      },
    }
  },
  featuresWebsitesPage: {
    title: 'Sites Web',
    hero: {
      chip: 'Constructeur de site web',
      text: {
        main: 'Donnez vie à vos idées avec l\'outil de construction de site web ',
        mainUp: 'Donnez vie à vos idées avec',
        mainDown: 'l\'outil de construction de site web',
        additional: 'Créez un site web personnalisé et professionnel pour une visibilité en ligne sans effort.'
      },
      btn: 'Rejoignez la liste d\'attente',
      btnGetFree: 'Obtenez mon compte gratuit',
    },
    features: {
      chip: 'Fonctionnalités',
      title: 'Créer un site web est maintenant un jeu d\'enfant avec notre processus simplifié',
      tailor: {
        title: 'Personnalisez le site selon votre marque',
        text: 'Personnalisez votre site web avec des modèles, des couleurs, des polices, et plus encore pour représenter votre entreprise.'
      },
      fastTrack: {
        title: 'Accélérez la création de votre site web',
        text: 'Commencez avec nos modèles pour accélérer le processus de construction.'
      },
      noCodeRequired: {
        title: 'Aucun code requis',
        text: 'Obtenez un site web responsive sans avoir besoin d\'expertise technique'
      }
    },
  },
  featuresPage: {
    title: 'Fonctionnalités',
    features: {
      testimonials: {
        chip: 'Témoignages',
        title: 'Ne vous fiez pas seulement à nos paroles',
        text: 'Témoignages Ne vous fiez pas seulement à nos paroles Voici ce que les gens disent de nous'
      },
      startNow: {
        chip: 'Commencez maintenant',
        title: 'Obtenez votre compte gratuit maintenant!',
        text: 'Nous simplifions et accélérons le processus, vous permettant de vous concentrer sur ce qui compte vraiment - développer votre entreprise.',
        btn: 'Rejoignez la liste d\'attente',
        btnGetFree: 'Obtenez un compte gratuit',
        placeholder: 'Entrez votre adresse e-mail',
      },
    },
  },
  featuresSalesPage: {
    title: 'FonctionnalitésVentes',
    hero: {
      chip: 'Entonnoirs de vente',
      text: {
        main: 'Constructeur d\'entonnoirs de vente facile à utiliser',
        mainUp: 'Facile à utiliser',
        mainDown: 'Constructeur d\'entonnoirs de vente',
        additional: 'Créez et optimisez facilement des entonnoirs de vente performants grâce à l\'IA',
      },
      btn: 'Rejoignez la liste d\'attente',
      btnGetFree: 'Obtenez mon compte gratuit',
    },
    logos: {
      text: 'Remplacez plusieurs abonnements par une plateforme unique et intégrée. Dites au revoir à tous...'
    },
    features: {
      chip: 'Fonctionnalités',
      title: 'Mettez votre entonnoir en marche en quelques minutes et laissez-nous nous occuper du reste',
      efficiency: {
        title: 'Efficacité parfaite',
        text: 'Optimisez le processus avec nos modèles testés et personnalisez-les à votre image.',
      },
      salesFunnel: {
        title: 'Entonnoir de vente avec IA',
        text: 'Laissez l\'IA créer votre entonnoir de vente idéal et vos pages web sans effort.',
        inputPlaceholder: 'Site web pour réserver un voyage intergalactique',
        btn: 'Rejoignez la liste d\'attente'
      },
      editor: {
        title: 'Entonnoirs faciles avec l\'éditeur',
        text: 'Créez des entonnoirs sans effort avec notre éditeur',
      },
      anything: {
        title: 'Vendez n\'importe quoi plus rapidement et plus facilement',
        text: 'Créez votre entonnoir et constatez une augmentation des ventes alors qu\'il fonctionne en autopilote pour développer les prospects et stimuler les conversions.',
      },

    },
  },
  featuresEmailPage: {
    title: 'Marketing par e-mail',
    hero: {
      chip: 'Campagnes par e-mail',
      text: {
        main: 'Boostez votre marketing avec des envois d\'e-mails rapides et faciles',
        mainUp: 'Boostez votre marketing avec',
        mainDown: 'des envois d\'e-mails rapides et faciles',
        additional: 'Optimisez votre flux de travail avec un outil de messagerie intégré pour vos entonnoirs et sites web, vous faisant gagner du temps et des efforts.'
      },
      btn: 'Rejoignez la liste d\'attente',
      btnGetFree: 'Obtenez mon compte gratuit',
    },
    features: {
      chip: 'Fonctionnalités',
      title: 'Intégrez votre liste d\'e-mails facilement dans les sites web et les entonnoirs',
      emailsWithAi: {
        title: 'E-mails avec IA',
        text: 'Laissez l\'IA créer vos campagnes d\'e-mails idéales sans effort.'
      },
      emailsMadeEasy: {
        title: 'E-mails simplifiés',
        text: 'Créez et envoyez facilement des e-mails personnalisés en quelques minutes avec notre éditeur et des modèles personnalisables.'
      },
      setUpSettings: {
        title: 'Paramétrage',
        text: 'Affinez le moment de votre message avec des réglages précis.'
      }
    }
  },
  featuresRelationshipPage: {
    title: 'Gestion de la relation client',
    hero: {
      chip: 'Gestion de la relation client',
      text: {
        main: 'Gérez les relations',
        mainUp: 'Gérez les relations avec',
        mainDown: 'Tous vos contacts',
        additional: 'Gérez chaque interaction que vos contacts ont avec votre entreprise en utilisant votre outil CRM personnalisé.'
      },
      btn: 'Rejoignez la liste d\'attente',
      btnGetFree: 'Obtenez mon compte gratuit',
    },
    features: {
      chip: 'Fonctionnalités',
      title: 'Se connecter avec vos clients n\'a jamais été aussi facile',
      centralize: {
        title: 'Centraliser la gestion des contacts',
        text: 'Gérez les transactions, les commandes ou les abonnements, vous avez tous les détails au même endroit.',
        selected: 'Sélectionné',
        csv: 'Exporter vers',
        pdf: 'Exporter en',
      },
      customTags: {
        title: 'Étiquettes personnalisées',
        text: 'Utilisez des étiquettes pour catégoriser, trier et gérer facilement les contacts.',
        items: {
          graphicDesign: 'Design graphique',
          englishCourse: 'Cours d\'anglais',
          marketingTeam: 'Équipe marketing',
          sales: 'Ventes',
          supportCenter: 'Centre de support',
          uiIx: 'UI/UX',
          development: 'Développement',
          qaTeam: 'Équipe QA',
          sketch: 'Sketch',
          businessAnalytic: 'Analyse commerciale',
        },
      },
    }
  },
  featuresAutomatePage: {
    title: 'Automatiser vos tâches',
    hero: {
      chip: 'Automatiser vos tâches',
      text: {
        main: 'Automatisez les processus de votre entreprise',
        mainUp: 'Automatisez votre entreprise',
        mainDown: 'Processus',
        additional: 'Laissez l\'automatisation gérer les détails les plus fins, afin que vous puissiez vous concentrer sur d\'autres priorités.'
      },
      btn: 'Rejoignez la liste d\'attente',
      btnGetFree: 'Obtenez mon compte gratuit',
    },
    features: {
      chip: 'Fonctionnalités',
      title: 'Accédez à toute votre automatisation des tâches essentielles en un seul endroit',
      streamline: {
        title: 'Optimisez votre flux de travail',
        text: 'Élaborez des règles d\'automatisation précises qui définissent des déclencheurs et des actions correspondantes, optimisant ainsi vos processus sans effort. ',
      },
      advanced: {
        title: 'Gestion avancée des flux de travail',
        text: 'Intégrez des retards et des scripts pour des séquences personnalisées selon vos besoins.',
        createTrigger: 'Créez votre premier déclencheur',
        personHasEmail: 'La personne a un "E-mail" qui commence par "1", ou est marquée avec "Page visitée"  ?'
      },
    }
  },
  blogPage: {
    title: 'Blog'
  },
  contactUsPage: {
    title: 'Contactez-nous',
    chip: 'Contactez-nous',
    header: {
      title: 'Contactez-nous pour obtenir de l\'aide',
      text: 'Vous rencontrez des problèmes de licence, de facturation ou techniques ? Vous souhaitez donner votre avis ? Vous recherchez des informations sur notre plan tarifaire ? N\'hésitez pas à nous contacter.',
    },
    form: {
      name: {
        name : 'Nom',
        label: 'Entrez votre nom',
      },
      email: {
        name : 'Email',
        label: 'Entrez votre adresse e-mail',
      },
      subject: {
        name : 'Sujet',
        label: 'Entrez le sujet',
      },
      message: {
        name : 'Message',
        label: 'Entrez le message',
      },
      btn: 'Soumettre'
    },
  },
  lang: {
    fr: 'Français',
    en: 'Anglais',
  },
  header: {
    login: 'Se connecter',
    getStarted: 'Rejoindre la liste d\'attente'
  },
  footer: {
    copyright: '© 2024 Smartbase. Tous droits réservés.',
    links: {
      salesFunnel: 'Entonnoir de vente',
      salesFunnels: 'Entonnoirs de vente',
      emailMarketing: 'Marketing par email',
      websiteBuilder: 'Constructeur de site web',

      liveWebinars: 'Webinaires en direct et formation en ligne',
      automateTasks: 'Automatiser les tâches',
      customerRelationship: 'Gestion de la relation client',
      resources: 'Ressources',
      blog: 'Blog',
      makeMoney: 'Gagner de l\'argent depuis chez vous',
      howToCreate: 'Comment créer un entonnoir de vente',
      beginnersGuide: 'Le guide du débutant pour le marketing par email',
      info: 'Info',
      pricing: 'Tarification',
      contactUs: 'Contactez-nous',
      help: 'Aide',
      legal: 'Légal',
      privacyPolicy: {
        title: 'Politique de confidentialité',
        chip: 'Politique de confidentialité',
        header: {
          title: 'Nous nous soucions de votre vie privée',
          text: 'Votre vie privée est importante pour nous chez Smartbase. Nous respectons votre vie privée en ce qui concerne les informations que nous pouvons collecter à votre sujet sur notre site Web.',
        },
        article: {
          first: {
            title: 'Quelles informations collectons-nous ?',
            paragraphOne: 'Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.',
            paragraphTwo: 'Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.',
            paragraphThree: 'Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',
          },
          second: {
            title: 'Comment utilisons-nous vos informations ?',
            paragraphOne: 'Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.',
            paragraphTwo: 'Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.',
            paragraphThree: 'Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',
          },
          third: {
            title: 'Quels sont vos droits en matière de confidentialité ?',
            paragraphOne: 'Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.',
            paragraphTwo: 'Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.',
            paragraphThree: 'Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',
          },
        }

      },
      terms: 'Conditions générales'
    }
  },
  authLogin: {
    username: 'Nom d\'utilisateur',
    password: 'Mot de passe',
    loginIn: 'Se connecter'
  },
  base: {
    emailAddress: 'Adresse e-mail',
  }
}

export default locale
