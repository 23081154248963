import PublicRepository from "@/repository/PublicRepository";
import StorageService from "@/services/storage.service";
import storageKeys from "@/constants/storageKeys";
import {defaultFilters} from "@/models/Blog/helpers";
import Pagination from "@/models/Pagination";


const state = {
  blogArticles: [],
  blogCategories: [],
  filters: StorageService.get(storageKeys.BLOG_FILTERS) || { ...defaultFilters },
  pagination: Pagination.defaultPagination(),
}

const getters = {
  blogCategories: state => state.blogCategories,
  blogArticles: state => state.blogArticles,
  filters: state => state.filters,
  pagination: state => state.pagination,
}

const actions = {
  async loadBlogCategories(context) {
    const { data } = await PublicRepository.blogCategories()
    context.commit('setBlogCategories', { data })
    return { items: data.items, pagination: data.pagination }
  },
  async loadBlogArticles(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.BLOG_FILTERS, filters)

    const { data } = await PublicRepository.blogArticles(filters)
    context.commit('setBlogArticles', { data, filters })

    return { items: data.items, pagination: data.pagination }
  },
  async loadBlogArticle(context, id) {
    const { data } = await PublicRepository.blogArticle(id)
    return data
  },
}

const mutations = {
  setBlogCategories(state, { data }) {
    state.blogCategories = [...data.items]
  },
  setBlogArticles(state, { data, filters }) {
    state.blogArticles = [...data.items]
    state.pagination = new Pagination(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
