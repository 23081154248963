import Vue from 'vue';

export default class SuperRepository {
  baseUri () { return '' }
  httpClient () { return Vue.prototype.$http }

  list = async (params) => this.httpClient().get(`${this.baseUri()}`, params);
  get = async (id, params = {}) => this.httpClient().get(`${this.baseUri()}/${id}`,params);
  post = async (data, params) => this.httpClient().post(`${this.baseUri()}`, data, params);
  patch = async (id, data, params) => this.httpClient().patch(`${this.baseUri()}/${id}`, data, params);
  put = async (id, data, params) => this.httpClient().put(`${this.baseUri()}/${id}`, data, params);
  delete = async (id) => this.httpClient().delete(`${this.baseUri()}/${id}`);
  query = async (path, params, config) => this.httpClient().request(`${this.baseUri()}/${path}`, params, config);
}
