import SuperRepository from './superRepository'

export default new class PublicRepository extends SuperRepository {
  baseUri () {
    return 'public'
  }
  blogCategories = async (params) => this.httpClient().get(this.baseUri() + '/category', params)
  blogArticles = async (params) => this.httpClient().get(this.baseUri() + '/article', params)
  blogArticle = async (id) => this.httpClient().get(this.baseUri() + '/article/' + id)
  helpCategories = async (params) => this.httpClient().get(this.baseUri() + '/question_category', params)
  helpCategory = async (id) => this.httpClient().get(this.baseUri() + '/question_category/' + id)
  helpArticles = async (params) => this.httpClient().get(this.baseUri() + '/question_article', params)
  helpArticle = async (id) => this.httpClient().get(this.baseUri() + '/question_article/' + id)

  waitingList = async (params) => this.httpClient().post(this.baseUri() + '/waiting_list', params)
  sendEmail = async (params) => this.httpClient().post(this.baseUri() + '/contact_us', params)

}
