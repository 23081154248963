export default class Pagination {
  constructor (pagination) {
    if (!pagination) {
      this.pagination = {
        page: 1,
        limit: 20
      }
      this.totalItems = 1
      this.total = 1
    } else {
      this.pagination = {
        page: pagination.currentPage,
        limit: pagination.limit
      }
      this.totalItems = pagination.maxPageNumber
      this.total = pagination.total
    }
  }

  static defaultPagination () {
    return new Pagination()
  }

  get isEmpty () {
    return this.total === 0
  }
}