import Vue from "vue";
import Vuex from "vuex";
import BlogModule from "@/store/Blog.module";
import ModalModule from "@/store/Modal.module";
import WaitingListModule from "@/store/WaitingList.module";
import HelpModule from "@/store/Help.module";
import ContactUsModule from "@/store/ContactUs.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    BlogModule,
    ModalModule,
    WaitingListModule,
    HelpModule,
    ContactUsModule
  },
});
