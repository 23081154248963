import Vue from 'vue';
import Vuetify from "vuetify";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    default: 'light',
    options: { customProperties: true },
    themes: {
      light: {
        white: '#FFF',
        purple900: '#1B0C3B',
        primary600: '#7E3AF2',
        gray700: '#344054',
        gray600: '#475467',
        gray300: '#D0D5DD',
        gray200: '#EAECF0',
        purple: {
          base: '#1B0C3B',
          c800: '#281b51',
          c200: '#30245d',
        }
      },
    },
  },
})

export default vuetify
