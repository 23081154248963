export const locale = {
  articlePage: {
    title: 'Blog article'
  },
  help: {
    inThisSection: 'In this section',
    askQuestion: 'Ask a question...',
  },
  blog:{
    sliderArticle1:{
      title: 'Marketing dictionary' ,
      desc: 'Here\'s How Upselling and Cross-Selling Could Increase Your Revenue by 30%!',
    },
    sliderArticle2:{
      title: 'Create your website' ,
      desc: 'A Complete Guide: How to Structure the Ultimate Long-Form Landing Page',
    },
    sliderArticle3:{
      title: 'Best tool for designers1' ,
      desc: 'I recently got my hands on smartbase, and holy crap, and holy crap',
    },
    viewAll: 'View all',
    sliderLink: 'Read more',
  },
  support: {
    support: 'Support',
    title: 'How can we help?',
    commonSearches: 'Common searches:',
    found: 'Haven’t found what you need?',
    touch: 'Get in touch — we’re happy to help!',
    placeholder: 'Ask a help...',
    button: 'Contact us'
  },
  thankYou:{
    title: 'You are now on our exclusive waiting list!',
    text:'We\'ll notify you with an invitation to your free account as'+
        'soon as our platform launches. Stay tuned for the exciting journey ahead with our all-in-one solution!',
    backHome: 'Back Home',
    metaTitle: 'Thank You Page'
  },
  modal:{
    title: 'Please provide the information \n' +
        'to verify your registration',
    description:'Smartbase is on the verge of being unveiled. '+
        'By joining our waitlist, you\'ll be among the initial beneficiaries.',
    email: 'Email*',
    emailPlaceholder: 'Enter email',
    firstName: 'First name',
    lastName: 'Last name',
    phoneNumber: 'Phone number',
    phoneNumberRequired: 'Phone number required',
    phoneLengthRequired: 'Field must contain 15 characters',
    agreement: 'Your data is used for waitlist subscription and Smartbase emails. Unsubscribe anytime per our',
    link: 'Privacy Policy.',
    phEmail: 'Enter email',
    phFirstName: 'Enter your first name',
    phLastName: 'Enter your last name',
    phPhone: '(xxx) xxx xxxx',
    btn: 'Confirm'
  },
  pricing: {
    name: 'Pricing',
    title: 'Choose the right plan for \n' +
        'your business',
    subtitle: 'Effortlessly create and optimize high-performing sales funnels using AI',
    toggle:{
      title: 'Choose billing period',
      btn1: 'Annually',
      btn2: 'Monthly'
    },
    cart:{
      starter: 'Starter',
      company: 'Company',
      enterprise: 'Enterprise',
      user: 'per user/month',
      btn: 'Get started'
    },
    table: {
      titles:{
        tailwind: 'Tailwind CSS code',
        figma: 'Figma design files',
        support: 'Support'
      },
      items:{
        basic: 'Basic components',
        application: 'Application UI',
        marketing: 'Marketing UI pre-order',
        commerce: 'E-commerce UI pre-order',
        edition: 'Free edition',
        community: 'Community',
        reports: 'Bug reports & feature requests',
        duration: 'Support duration',
        year: '1 Year'
      },
    },
    btn: 'Get free account',
    placeholder: 'Enter your email',
    links: {
      viewAll: ' (view all)',
      viewDemo: ' (view demo)',
      duplication:  ' (duplicate in Figma)',
      preview: ' (preview in Figma)'
    }
  },
  generalHelp:{
    btnContents: 'Contents',
    btnCansel: 'Cancel',
  },
  mainPage: {
    title: 'Smart Base',
    revolutionizing: {
      title: 'Revolutionizing<br>Your Online Business',
      subtitle1: 'Unleash the Power of',
      subtitle2: 'and',
      subtitle3: 'All in One AI-Driven Platform',
      link1: 'Sales Funnels',
      link2: 'Website Building',
      link3: 'Email Marketing',
      join: 'Join over 1,200 curious people ready to discover',
      email: 'Email address',
      btn: 'Join the waiting list'
    },
    platforms: {
      title: 'Replace Multiple Subscriptions with a Single, Integrated Platform. Say Goodbye to all of them...'
    },
    integration: {
      chip: 'AI integration',
      title: 'Artificial Intelligence at Your Service',
      subtitle: 'You will harness the power of AI to automate and optimize your content. Imagine being able to create ' +
          'your web pages, sales pages, blog articles, emails, all efficiently generated by AI. This frees up your time ' +
          'to focus on growing your business.'
    },
    solution: {
      chip: 'Features',
      title: 'All-in-One Solution: Streamline Your Online Business with Ease',
      subtitle: 'Tons of others love building and shipping sites with smartbase.',
      replaces: 'Replaces',
      learnMore: 'Learn more',
      solutionItem1: {
        title: 'Create Sales Funnels',
        subtitle: 'Begin your journey with our platform by crafting high-converting sales funnels. Use our ' +
            'intuitive tools to design each stage of the customer journey, from initial interest to final purchase, ' +
            'enhancing your sales process with efficiency and precision.'
      },
      solutionItem2: {
        title: 'Send Email Campaigns',
        subtitle: 'Engage your audience through targeted email campaigns. Our platform enables you to easily ' +
            'create, manage, and track the performance of your emails, ensuring they resonate with your audience ' +
            'and drive meaningful interactions.'
      },
      solutionItem3: {
        title: 'Create Websites',
        subtitle: 'Build professional, responsive websites without any coding knowledge. Our drag-and-drop website ' +
            'builder is designed to support you create stunning online spaces that truly reflect your brand and ' +
            'attract more visitors.'
      },
      solutionItem4: {
        title: 'Create Live Webinars & Online Training',
        subtitle: 'Expand your reach and educational impact with online courses and live webinars. Whether it’s for ' +
            'onboarding, training, or engaging with your audience, our tools make it easy to create and host ' +
            'compelling online educational content.'
      },
      solutionItem5: {
        title: 'Automate Your Tasks',
        subtitle: 'Increase productivity by automating repetitive tasks. From marketing workflows to sales ' +
            'processes, our automation tools support you save time, reduce errors, and focus on more strategic ' +
            'aspects of your business.'
      },
      solutionItem6: {
        title: 'Customer Relationship Management',
        subtitle: 'Manage your customer relationships effectively. Our integrated CRM system allows you to track ' +
            'customer interactions, analyze behavior, and personalize your approach to enhance customer ' +
            'satisfaction and loyalty.'
      },
    },
    getStarted: {
      title: 'Ready to go live?<br>AI Simplified: One Tool, Multiple Functions',
      btn: 'Join the waiting list',
      btnJoinUs: 'Join us',
    },
    testimonials: {
      chip: 'Testimonials',
      title: 'Don\'t Just Take Our Word For It',
      subtitle: 'Here’s what people are saying about us'
    }
  },
  pricingPage: {
    title: 'Pricing',
  },
  errorPage: {
    title: '404',
    header: {
      meta: 'Ooops! We lost this page',
      ops: 'Ooops!',
      title: 'We lost this page',
      text: 'Even with the brightest light we couldn\'t find what you’re looking for. Let’s find a better place for you to go.',
      btn: 'Back Home',
    }

  },
  featuresWebinarPage: {
    title: 'Webinar',
    hero: {
      chip: 'Live Webinars & Online Training',
      text: {
        main: 'Host Webinar, Craft Courses — No Tech Skills Needed',
        mainUp: 'Host Webinar, Craft Courses',
        mainDown: '— No Tech Skills Needed',
        additional: 'Live webinars and Online courses offer an engaging strategy to scale your\n' +
          'digital business and establish a sustainable income stream.'
      },
      btn: 'Join the waiting list',
      btnGetFree: 'Get my free account',
    },
    logos: {
      text: 'Replace Multiple Subscriptions with a Single, Integrated Platform. Say Goodbye to all of them...'
    },
    features: {
      chip: 'Features',
      title: 'Quickly Develop And Launch Webinars And Online Courses',
      education: {
        title: 'Education with AI',
        text: 'Let AI craft your ideal online course and live webinar effortlessly.',
      },
      webinar: {
        title: 'Create Webinar In Just A Few Clicks',
        text: 'Easily create pages using templates with our intuitive builder.'
      },
      chat: {
        title: 'Live Chat',
        text: 'Live interaction is crucial for engaging webinars.'
      },
      quiz: {
        title: 'Engage Your Audience Meaningfully',
        text: 'Tailored Student Features for Enhanced Learning and Satisfaction.'
      },
      course: {
        title: 'Craft Your Course',
        text: 'Customize your course effortlessly with our flexible builder.'
      },
    }
  },
  featuresWebsitesPage: {
    title: 'Websites',
    hero: {
      chip: 'Website builder',
      text: {
        main: 'Bring Your Ideas To Life With Website-Building Tool ',
        mainUp: 'Bring Your Ideas To Life With',
        mainDown: 'Website-Building Tool',
        additional: 'Build a personalized and professional website for effortless online visibility.'
      },
      btn: 'Join the waiting list',
      btnGetFree: 'Get my free account',
    },
    features: {
      chip: 'Features',
      title: 'Creating A Website Is Now A Breeze With Our Simplified Process',
      tailor: {
        title: 'Tailor Site To Your Brand',
        text: 'Customize your website with templates, colors, fonts, and more to represent your business.'
      },
      fastTrack: {
        title: 'Fast-Track Your Website Creation',
        text: 'Begin with our templates to accelerate the construction process.'
      },
      noCodeRequired: {
        title: 'No Code Required',
        text: 'Achieve responsive website without requiring technical expertise.'
      }
    },
  },
  featuresPage: {
    title: 'Features',
    features: {
      testimonials: {
        chip: 'Testimonials',
        title: 'Don\'t just take our word for it',
        text: 'Testimonials Don\'t just take our word for it Here’s what people are saying about us'
      },
      startNow: {
        chip: 'Start now',
        title: 'Get your free account now!',
        text: 'We simplify and expedite the process, allowing you to concentrate on what truly matters - expanding your business.',
        btn: 'Join the waiting list',
        btnGetFree: 'Get free account',
        placeholder: 'Enter your email',
      },
    },
  },
  featuresSalesPage: {
    title: 'FeaturesSales',
    hero: {
      chip: 'Sales funnels',
      text: {
        main: 'Easy-to-Use Sales Funnel Builder',
        mainUp: 'Easy-to-Use',
        mainDown: 'Sales Funnel Builder',
        additional: 'Effortlessly create and optimize high-performing sales funnels using AI',
      },
      btn: 'Join the waiting list',
      btnGetFree: 'Get my free account',
    },
    logos: {
      text: 'Replace Multiple Subscriptions with a Single, Integrated Platform. Say Goodbye to all of them...'
    },
    features: {
      chip: 'Features',
      title: 'Get your funnel up and running in minutes, and let us handle the rest',
      efficiency: {
        title: 'Efficiency Perfected',
        text: 'Streamline the process with our tested templates and customize to your brand.',
      },
      salesFunnel: {
        title: 'Sales Funnel with AI',
        text: 'Let AI craft your ideal sales funnel and web pages effortlessly.',
        inputPlaceholder: 'Website to book intergalactic travel',
        btn: 'Join the waiting list'
      },
      editor: {
        title: 'Easy Funnels with Editor',
        text: 'Create funnels effortlessly with our editor',
      },
      anything: {
        title: 'Sell anything faster and easier',
        text: 'Create your funnel and witness a surge in sales as it works on autopilot to grow leads and boost conversions.',
      },

    },
  },
  featuresEmailPage: {
    title: 'Email marketing',
    hero: {
      chip: 'Email Campaigns',
      text: {
        main: 'Boost Your Marketing With Swift And Easy Emailing',
        mainUp: 'Boost Your Marketing With',
        mainDown: 'Swift And Easy Emailing',
        additional: 'Streamline your workflow with an integrated email tool for your funnels and websites, saving you time and effort.'
      },
      btn: 'Join the waiting list',
      btnGetFree: 'Get my free account',
    },
    features: {
      chip: 'Features',
      title: 'Integrate Your Email List With Ease Into Websites And Funnels',
      emailsWithAi: {
        title: 'Emails With AI',
        text: 'Let AI craft your ideal email campaigns effortlessly.'
      },
      emailsMadeEasy: {
        title: 'Emails Made Easy',
        text: 'Effortlessly create and send personalized emails in minutes with our editor and customizable templates.'
      },
      setUpSettings: {
        title: 'Set Up Settings',
        text: 'Perfect your message timing with precision settings.'
      }
    }
  },
  featuresRelationshipPage: {
    title: 'Customer Relationship Management',
    hero: {
      chip: 'Customer Relationship Management',
      text: {
        main: 'Manage Relationships',
        mainUp: 'Manage Relationships With',
        mainDown: 'All Your Contacts',
        additional: 'Manage every interaction your contacts have with your company using your customized CRM tool.'
      },
      btn: 'Join the waiting list',
      btnGetFree: 'Get my free account',
    },
    features: {
      chip: 'Features',
      title: 'Connecting With Your Customers Has Never Been Easier',
      centralize: {
        title: 'Centralize Contact Management',
        text: 'Manage transaction, orders, or subscriptions, you have all the details in one place.',
        selected: 'Selected',
        csv: 'Export to',
        pdf: 'Export in',
      },
      customTags: {
        title: 'Custom tags',
        text: 'Utilise tags to categorise, sort, and manage contacts easily.',
        items: {
          graphicDesign: 'Graphic Design',
          englishCourse: 'English course',
          marketingTeam: 'Marketing team',
          sales: 'Sales',
          supportCenter: 'Support Center',
          uiIx: 'UI/UX',
          development: 'Development',
          qaTeam: 'QA Team',
          sketch: 'Sketch',
          businessAnalytic: 'Business Analytic',
        },
      },
    }
  },
  featuresAutomatePage: {
    title: 'Automate Your Tasks',
    hero: {
      chip: 'Automate Your Tasks',
      text: {
        main: 'Automate Your Business Processes',
        mainUp: 'Automate Your Business',
        mainDown: 'Processes',
        additional: 'Let automation manage the finer details, so you can focus on other priorities.'
      },
      btn: 'Join the waiting list',
      btnGetFree: 'Get my free account',
    },
    features: {
      chip: 'Features',
      title: 'Access All Your Essential Task Automation In One Place',
      streamline: {
        title: 'Streamline Your Workflow',
        text: 'Craft precise automation rules that define triggers and corresponding actions, effortlessly optimizing your processes. ',
      },
      advanced: {
        title: 'Advanced Workflow Management',
        text: 'Integrate delays and scripts for custom sequences to meet your needs.',
        createTrigger: 'Create your first trigger',
        personHasEmail: 'Person has a "Email" begins "1", or is tagged with "Page visited" ?'
      },
    }
  },
  blogPage: {
    title: 'Blog',
    pagination:{
      prev: 'Previous',
      next: 'Next'
    },
  },
  contactUsPage: {
    title: 'Contact us',
    chip: 'Contact us',
    header: {
      title: 'Get in touch how can we support',
      text: 'Experiencing licensing, billing, or technical challenges? Interested in providing feedback? Seeking information about our pricing plan? Feel free to reach out to us.',
    },
    form: {
      name: {
        name : 'Name',
        label: 'Enter name',
      },
      email: {
        name : 'Email',
        label: 'Enter email',
      },
      subject: {
        name : 'Subject',
        label: 'Enter subject',
      },
      message: {
        name : 'Message',
        label: 'Enter message',
      },
      btn: 'Submit'
    },
  },
  lang: {
    fr: 'Francais',
    en: 'English',
  },
  header: {
    login: 'Log in',
    getStarted: 'Join the waiting list'
  },
  footer: {
    copyright: '© 2024 Smartbase. All rights reserved.',
    links: {
      salesFunnel: 'Sales funnel',
      salesFunnels: 'Sales Funnels',
      emailMarketing: 'Email marketing',
      websiteBuilder: 'Website builder',

      liveWebinars: 'Live Webinars & Online Training',
      automateTasks: 'Automate Tasks',
      customerRelationship: 'Customer Relationship Management',
      resources: 'Resources',
      blog: 'Blog',
      makeMoney: 'Make money from home',
      howToCreate: 'How to create a sales funnel',
      beginnersGuide: 'The berinner\'s guide to email marketing',
      info: 'Info',
      pricing: 'Pricing',
      contactUs: 'Contact us',
      help: 'Help',
      legal: 'Legal',
      privacyPolicy: {
        title: 'Privacy policy',
        chip: 'Privacy Policy',
        header: {
          title: 'We care about your privacy',
          text: 'Your privacy is important to us at Smartbase. We respect your privacy regarding any information we may collect from you across our website.',
        },
        article: {
          first: {
            title: 'What information do we collect?',
            paragraphOne: 'Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.',
            paragraphTwo: 'Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.',
            paragraphThree: 'Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',
          },
          second: {
            title: 'How do we use your information?',
            paragraphOne: 'Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.',
            paragraphTwo: 'Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.',
            paragraphThree: 'Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',
          },
          third: {
            title: 'What are your privacy rights?',
            paragraphOne: 'Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.',
            paragraphTwo: 'Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.',
            paragraphThree: 'Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',
          },
        }

      },
      terms: 'Terms and conditions'
    }
  },
  authLogin: {
    username: 'Username',
    password: 'Password',
    loginIn: 'Login In'
  },
  base: {
    emailAddress: 'Email address',
    noElementsFound: 'No element found',
    startTypingText: '',
  },
  error: {
    required: '{name} required',
    email: 'Wrong Email format',
    maxLength: 'Max length - {length} symbols',
    minLength: 'Min length - {length} symbols',
  }
}

export default locale
