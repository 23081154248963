import PublicRepository from "@/repository/PublicRepository";


const state = {

}

const getters = {

}

const actions = {
  async addWaitingListUser(context, payload) {
    await PublicRepository.waitingList(payload)
    // context.commit('setBlogCategories', { data })
    // return { items: data.items, pagination: data.pagination }
  },
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
