import store from 'store'
import expirePlugin from 'store/plugins/expire'

store.addPlugin(expirePlugin)

export default class StorageService {
  static set (key, val, expire) {
    if (expire) {
      return store.set(key, val)
    }

    store.set(key, val)
  }

  static get (key) {
    return store.get(key)
  }

  static remove (key) {
    return store.remove(key)
  }
}
