export function stringCroppedView(string, length = 15) {
  if (!string) return
  return string.length > length
    ? string.substring(0, length) + '...'
    : string
}

export function croppedStringWithEllipsis(string, length = 12) {
  if (!string) return;

  const maxLength = 2 * length + 3; // Общая максимальная длина (length символов в начале + 3 многоточия + length символов в конце)

  if (string.length > maxLength) {
    const startSubstring = string.substring(0, length);
    const endSubstring = string.substring(string.length - length);
    return startSubstring + ' ... ' + endSubstring;
  } else {
    return string;
  }
}
