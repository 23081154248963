import axios from 'axios'
import i18nService from '../services/i18n.service'

const axiosApiInstance = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_ORIGIN}/api`,
})

axiosApiInstance.interceptors.request.use(
  config => {
    config.headers = {
      Accept: 'application/json',
      'Accept-Language': config.loc || i18nService.getActiveLanguage(),
      ...config.headers,
    }
    return config
  },
  error => {
    Promise.reject(error)
  },
)

axiosApiInstance.interceptors.response.use(
  response => {
    return response
  },
  async function (err) {
    return Promise.reject(err)
  },
)

export default axiosApiInstance
