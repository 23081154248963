import { hasPermission } from '@/helpers/permission'

function checkPermission(el, binding) {
  const { value } = binding
  const exist = hasPermission(value)

  if (!exist) {
    el.parentNode && el.parentNode.removeChild(el)
  }
}

export default {
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  update(el, binding) {
    checkPermission(el, binding)
  },
}
