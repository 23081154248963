import PublicRepository from "@/repository/PublicRepository";
import StorageService from "@/services/storage.service";
import storageKeys from "@/constants/storageKeys";
import {defaultFilters} from "@/models/Help/helpers";
import Pagination from "@/models/Pagination";


const state = {
  helpArticles: [],
  helpCategories: [],
  filters: StorageService.get(storageKeys.HELP_FILTERS) || { ...defaultFilters },
  pagination: Pagination.defaultPagination(),
}

const getters = {
  helpCategories: state => state.helpCategories,
  helpArticles: state => state.helpArticles,
  filters: state => state.filters,
  pagination: state => state.pagination,
}

const actions = {
  async loadHelpCategories(context) {
    const { data } = await PublicRepository.helpCategories({filter: {active: 1}})
    context.commit('setHelpCategories', { data })
    return { items: data.items, pagination: data.pagination }
  },
  async loadHelpCategory(context, id) {
    const { data } = await PublicRepository.helpCategory(id)
    return data
  },
  async loadHelpCategoryArticles(context, id) {
    const { data } = await PublicRepository.helpArticles({filter: {
      category: {
        id: {
          value: id,
          operator: 'in'
        }
      },
      active: 1
    }})
    return data
  },
  async loadHelpArticles(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.HELP_FILTERS, filters)

    const { data } = await PublicRepository.helpArticles(filters)
    context.commit('setHelpArticles', { data, filters })

    return { items: data.items, pagination: data.pagination }
  },
  async loadHelpArticle(context, id) {
    const { data } = await PublicRepository.helpArticle(id)
    return data
  },
}

const mutations = {
  setHelpCategories(state, { data }) {
    let preparedData = data.items.map(item => {
      item.pages = item.pages.map(el => {
        if (!el.isActive) return
        el.category = {id: item.id, title: item.title}
        return el
      })
      return item
    })
    state.helpCategories = [...preparedData]
  },
  setHelpArticles(state, { data, filters }) {
    state.helpArticles = [...data.items]
    state.pagination = new Pagination(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
