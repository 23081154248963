import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from '@/services/api.service'
import vuetify from '@/plugins/vuetify'
import LottieAnimation from "@/plugins/lottie-web";
import i18n from '@/plugins/vue-i18n'
import VueTelInput from 'vue-tel-input';
import LoadScript from 'vue-plugin-load-script'; // for google captcha
import '@/helpers/vue-filters'
import '@/plugins/vue-multiselect'
import '@/plugins/directives'
import '@/plugins/v-tootip'
import 'vuetify/dist/vuetify.min.css'
import '@splidejs/splide/dist/css/splide.min.css';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(LoadScript);
Vue.config.productionTip = false;

Vue.http = Vue.prototype.$http = ApiService

new Vue({
  router,
  store,
  vuetify,
  i18n,
  VueTelInput,
  LottieAnimation,
  render: (h) => h(App),
}).$mount("#app");
