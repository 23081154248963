import publicRepository from "@/repository/PublicRepository";


const state = {

}

const getters = {

}

const actions = {
    async sendData(context, payload) {
        const data = await publicRepository.sendEmail(payload)
        console.log(data)
        return data
    }
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
