import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/plugins/vue-i18n";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      component: () => import("@/layouts/DefaultLayout.vue"),
      children: [
        {
          path: '/',
          name: 'MainPage',
          meta: { title: 'mainPage.title' },
          component: () => import('@/pages/Main.vue'),
        },
        {
          path: '/features-webinar',
          name: 'FeaturesWebinarPage',
          meta: { title: 'featuresWebinarPage.title' },
          component: () => import('@/pages/features-webinar/index.vue'),
        },
        {
          path: '/features-websites',
          name: 'FeaturesWebsitesPage',
          meta: { title: 'featuresWebsitesPage.title' },
          component: () => import('@/pages/features-websites/index.vue'),
        },
        {
          path: '/features-sales',
          name: 'FeaturesSalesPage',
          meta: { title: 'featuresPage.title' },
          component: () => import('@/pages/features-sales/index.vue'),
        },
        {
          path: '/features-email-marketing',
          name: 'FeaturesEmailPage',
          meta: { title: 'featuresEmailPage.title' },
          component: () => import('@/pages/features-email/index.vue'),
        },
        {
          path: '/features-relationship',
          name: 'FeaturesRelationshipPage',
          meta: { title: 'featuresRelationshipPage.title' },
          component: () => import('@/pages/features-relationship/index.vue'),
        },
        {
          path: '/features-automate',
          name: 'FeaturesAutomatePage',
          meta: { title: 'featuresAutomatePage.title' },
          component: () => import('@/pages/features-automate/index.vue'),
        },
        {
          path: '/pricing',
          name: 'PricingPage',
          meta: { title: 'pricingPage.title' },
          component: () => import('@/pages/pricing/index.vue'),
        },
        {
          path: '/blog',
          name: 'BlogPage',
          meta: { title: 'blogPage.title' },
          component: () => import('@/pages/blog/index.vue'),
        },
        {
          path: '/blog/article/:id',
          name: 'ArticlePage',
          meta: { title: 'articlePage.title' },
          component: () => import('@/pages/article/index.vue'),
        },
        {
          path: '/support',
          name: 'SupportPage',
          meta: { title: 'support.support' },
          component: () => import('@/pages/help/index.vue'),
        },
        {
          path: '/support/:category',
          component: () => import('@/pages/help/aside-view/index.vue'),
          children: [
            {
              path: '/support/:id/:category',
              name: 'HelpCategoryPage',
              meta: { title: 'support.support' },
              component: () => import('@/components/Support/HelpCategoryPage.vue'),
            },
            {
              path: '/support/:id/:category/:articleId',
              name: 'HelpArticlePage',
              meta: { title: 'support.support' },
              component: () => import('@/components/Support/HelpArticlePage.vue'),
            },
          ]
        },
        {
          path: '/thank-you',
          name: 'ThankYouPage',
          meta: { title: 'thankYou.metaTitle' },
          component: () => import('@/pages/thank-you/index.vue'),
        },
        {
          path: '/contact-us',
          name: 'ContactUsPage',
          meta: { title: 'contactUsPage.title' },
          component: () => import('@/pages/contact-us/index.vue'),
        },
        {
          path: '/privacy-policy',
          name: 'PrivacyPolicyPage',
          meta: { title: 'footer.links.privacyPolicy.header.title'},
          component: () => import('@/pages/privacy-policy/index.vue'),
        },
        {
          path: '/404',
          name: 'PageError404',
          meta: { title: `errorPage.header.meta` },
          component: () => import('@/pages/error/index.vue'),
        },
        { path: '*', redirect: '/404', hidden: true },
      ]
    },
  ]
});

router.beforeEach((to, from, next) => {
  // set page tab name
  document.title = i18n.t(`${to.meta.title}`)
  next()
})

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router;
