import { isObject } from 'lodash'
import {disablePageScroll, enablePageScroll, getScrollState} from "@/directives/scroll-lock";

function getScrollLockOptions (bindingValue) {
  const options = {
    value: false,
    scrollableSelector: null
  }

  if (isObject(bindingValue)) {
    return {
      ...options,
      ...bindingValue
    }
  }

  return {
    ...options,
    value: bindingValue
  }
}

function getScrollableElement (scrollableSelector) {
  return scrollableSelector ? document.querySelector(scrollableSelector) : null
}

const scrollLockDirective = {
  inserted (el, binding) {
    const { value, scrollableSelector } = getScrollLockOptions(binding.value)

    if (value) {
      setTimeout(() => {
        const scrollableElement = getScrollableElement(scrollableSelector)

        if (scrollableElement) {
          disablePageScroll(scrollableElement)
        }
      })
    }
  },

  componentUpdated (el, binding) {
    setTimeout(() => {
      const { value, scrollableSelector } = getScrollLockOptions(binding.value)

      const scrollableElement = getScrollableElement(scrollableSelector)

      if (!scrollableElement) {
        return
      }

      if (value && getScrollState()) {
        disablePageScroll(scrollableElement)
      } else if (!value) {
        enablePageScroll(scrollableElement)
      }
    })
  },

  unbind () {
    enablePageScroll()
  }
}

export default scrollLockDirective
