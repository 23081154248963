const types = {
  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',
  MODAL_CLOSE_ALL: 'MODAL_CLOSE_ALL'
}

const state = {
  activeModals: []
}

const getters = {
  list: (state) => state.activeModals,
  current: (state) => {
    const lastIndex = state.activeModals.length - 1
    return state.activeModals[lastIndex]
  }
}

const actions = {
  async open (context, modalOptions) {
    return new Promise((resolve, reject) => {
      const modalSettings = {
        component: modalOptions.extends,
        name: modalOptions.extends.name || '',
        props: modalOptions.props || {},
        listeners: modalOptions.listeners || {},
        id: modalOptions.reuse && modalOptions.extends.name ? modalOptions.extends.name : +new Date(),
        historyURL: modalOptions.historyURL,
        scrollableElSelector: modalOptions.scrollableElSelector,
        resolve,
        reject
      }

      context.commit(types.MODAL_OPEN, modalSettings)
    })
  },

  close (context, modalOptions) {
    context.commit(types.MODAL_CLOSE, modalOptions.id)
  },

  closeAll (context) {
    context.commit(types.MODAL_CLOSE_ALL)
  }
}

const mutations = {
  MODAL_OPEN (state, modalOptions) {
    const position = state.activeModals.findIndex(
      modal => modal.id === modalOptions.id
    )

    if (position !== -1) {
      state.activeModals = [
        state.activeModals[position],
        ...state.activeModals.filter((modal, index) => index !== position)
      ]
    } else {
      state.activeModals = [
        ...state.activeModals,
        modalOptions
      ]
    }
  },

  MODAL_CLOSE (state, modalId) {
    state.activeModals = state.activeModals.filter(modal => modal.id !== modalId)
  },

  MODAL_CLOSE_ALL (state) {
    state.activeModals = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
